<template lang="pug">
	#sections
		el-dialog.override-width(:title='action === "create" ? "Invoke a network campaign" : `Update the network campaign #${form.uuid}`', :visible.sync='setIntegrationModal', width='85%', center='')
			el-form()
				//h4.text-left
					b Override Parameters
				//p.text-left If set, these parameters will override the parameters that were set in "integration" at "misc" section for this network campaign.

				el-row()
					h4
						b Booleans
				el-row()
					el-col(:xs="24", :sm="4")
						el-form-item(label="Allow Duplicates" label-position="top")
							el-switch.w-100(v-model="form.allow_duplicates" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="4")
						el-form-item(label="Is Strict Phone Number" label-position="top")
							el-switch.w-100(v-model="form.is_strict_phone" active-text="On" inactive-text="Off")

					el-col(:xs="24", :sm="4")
						el-form-item(label="Redirect Params" label-position="top")
							el-switch.w-100(v-model="form.allow_redirect_params_reg" active-text="On" inactive-text="Off")

				el-row()
					h4.mt-3
						b JustClick
				el-row(:gutter="24")
					el-col(:sm="12" :lg="8")
						el-form-item(label="Justclick Lead" label-position="top")
							el-input( v-model="form.jc_step_1")
					el-col(:sm="12" :lg="8")
						el-form-item(label="Justclick Customer" label-position="top")
							el-input( v-model="form.jc_step_2")
					el-col(:sm="12")
				el-row(:gutter="12")
					h4.text-left
						b Telegram

				el-row(:gutter="12")
					el-col(:xs="24")
						el-form-item(label="Telegram Notification Channel" label-position="top")
							.w-100.text-left.d-block
								el-tag(:key='tag', v-for='tag in form.tg_channel_id', closable='', :disable-transitions='false', @close='handleClose(tag)')
									| {{tag}}
								el-input.input-new-tag(v-if='inputVisible', v-model='inputValue', ref='saveTagInput', size='mini', @keyup.enter.native='handleInputConfirm', @blur='handleInputConfirm')
								el-button.button-new-tag(v-else='', size='small', @click='showInput') + New Tag

				el-row()
					h4.mt-3
						b Misc
				el-row(:gutter="24")
					el-col(:sm="12" :md="8")
						el-form-item(label="Language")
							el-select.w-100(filterable="", v-model="form.language")
								el-option.text-bold(label='Select Language', value='')
								el-option(v-for="o of language_options", :label="o.t", :value="o.v")
					el-col(:sm="12" :md="8")
						el-form-item(label="Custom Fix" label-position="top")
							el-select.w-100(v-model="form.custom_fix_id")
								el-option(:value="null" label="- Select Custom Fix -")
								el-option(v-for="o of customFixOptions" :label="o.t" :value="o.v")

				el-divider
				h4.text-left
					b Override Parameters
				p.text-left If set, these parameters will override the parameters that were set in "integration" at "misc" section for this funnel.
				p.text-success
					i Note: You can add dynamic properties to the request payload by adding a prefix "#key_" to a param name, so, the property without the prefix will be added to the payload as a MISC param.
				el-row(:gutter="12")
					el-col(:span="24")
						el-form-item(label="Override Parameters")
							el-button(type="info" icon="el-icon-plus" @click="addRow('override_params')") Add
						.w-100
							el-row.mt-1.mb-1(v-for="(o, i) of form.override_params" :gutter="6")
								el-col.mb-sm-1(:sm="10" :md="8")
									el-input.sm-gap-1(v-model="o.key" placeholder="Key")
								el-col.mb-sm-1(:sm="12" :md="14")
									el-input.sm-gap-1(v-model="o.value" placeholder="Value")
								el-col.mb-sm-1(:xs="3" :sm="2" :md="1")
									el-button.sm-gap-1(icon="el-icon-minus" type="danger" @click="() => form.override_params.splice(i,1)")

				el-divider
				h4.text-left
					b URI Query Params To Payload Fields Mapping
				p.text-left The following params would be caught from the URI. For example - the landing page was loaded with&nbsp;
					span.text-danger clickId=qqqwwweee&nbsp;
					| , but, you want to send the broker to a field "custom1" - You must choose the key and map to the foreign field.

				el-row(:gutter="12")
					el-col(:span="24")
						el-form-item(label="Fields Mapping")
							el-button(type="info" icon="el-icon-plus" @click="addRow('fields_mapping')") Add
						el-row.mt-1.mb-1(v-for="(o, i) of form.fields_mapping" :gutter="6")
							el-col(:span="8")
								el-input(v-model="o.key" placeholder="Query Param")
							el-col(:span="15")
								el-input(v-model="o.value" placeholder="Foreign Field")
							el-col(:span="1")
								el-button(icon="el-icon-minus" type="danger" @click="() => form.fields_mapping.splice(i,1)")
				el-divider

				h4.text-left
					b Override Traffic Source Params
				p.text-left You can toggle on and off mapping the traffic source param. You must toggle on in order to map the traffic source id to the internal one. You must also set the&nbsp;
					| key that the application will listen to and the key in the request payload that will be sent to the brands. <b style="color:red">@todo add database</b>
					el-row(:gutter="12")
						el-col(:xs="12", :sm="6")
							el-form-item(label="Incoming Param" label-position="top")
								el-tooltip(v-if="form.override_tracking_params === 'OFF' || form.override_tracking_params === 'DATABASE'" content='Change the mapping of traffic source param to "incoming"')
									el-input(v-model="form.incoming_raw_ts_param" placeholder="Incoming Param" :disabled="form.override_tracking_params === 'OFF' || form.override_tracking_params === 'DATABASE'")
								el-input(v-else="" v-model="form.incoming_raw_ts_param" placeholder="Incoming Param" :disabled="form.override_tracking_params === 'OFF' || form.override_tracking_params === 'DATABASE'")
						el-col(:xs="12", :sm="6")
							el-form-item(label="Param In Payload" label-position="top")
								el-tooltip(v-if="form.override_tracking_params === 'OFF' " content='Change the mapping of traffic source param to either "incoming" or "database"')
									el-input(v-model="form.outgoing_mapped_ts_param" placeholder="Param in payload" :disabled="form.override_tracking_params === 'OFF'")
								el-input(v-if="form.override_tracking_params !== 'OFF'"	v-model="form.outgoing_mapped_ts_param" placeholder="Param in payload" :disabled="form.override_tracking_params === 'OFF'")
						el-col(:xs="24", :sm="6")
							el-form-item(label="Toggle Mapping Type" label-position="top")
								el-radio-group.w-100(v-model="form.override_tracking_params"	:fill="form.override_tracking_params === 'INCOMING' ? '#409EFF' : form.override_tracking_params === 'DATABASE' ? 'orange' : 'red'")
									el-radio-button(label="INCOMING") Incoming
									el-radio-button(label="DATABASE") Database
									el-radio-button(label="OFF") Off
				el-row()
					.d-flex.justify-content-center
						el-button(type="primary" icon="el-icon-plus" @click="setNetworkCampign") Invoke

		el-row(:gutter="24")
			el-col(:sm="24" :md="12")
				el-form(:form="form" :rules="rules")
					el-row(:gutter="10")
						el-col(:xs="24" :sm="12")
							el-form-item(label="By Keyword" label-position="top" label-width="auto")
								el-input(type="text", placeholder="Search By Keyword...", v-model="paginate.keyword", v-debounce:300ms="fetchData")
						el-col(:xs="24" :sm="12")
							el-form-item(label="Is In Use" label-position="top" label-width="auto")
								el-select.w-100(filterable="", v-model="paginate.in_use", @change="fetchData")
									el-option(value="" label="- Select In Use -" )
									el-option(value="1" label="Yes")
									el-option(value="0" label="No")
					el-table.funnels-tbl(id	="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
						el-table-column(align='center', label='#' :width='55')
							template(slot-scope='scope')
								| {{ scope.row.id }}

						el-table-column( align='center', label="Name")
							template(slot-scope="scope")
								span.chooseable() {{ scope.row.name || '-' }}

						el-table-column( align='center', label="UUID" width="250px" )
							template(slot-scope="scope")
								span.chooseable.d-block()
									| {{ scope.row.uuid || '-' }}
								small.text-success.d-block ({{ scope.row.short_uuid }})

						el-table-column(align="center" label="In Use" )
							template(slot-scope="scope")
								font-awesome-icon.icon.alt.big-icon.text-success(v-if="scope.row.in_use" v-b-tooltip.html.hover-right="", title="In Use", size="lg", :icon="['fas', 'plus']")
								font-awesome-icon.icon.alt.big-icon.text-danger(v-else="" v-b-tooltip.html.hover-right="", title="In Use", size="lg", :icon="['fas', 'minus']")

						el-table-column(align="center" label="Actions" :width="180" )
							template(slot-scope="scope")
								.d-flex.justify-content-center
									el-button(v-b-tooltip.html.hover.top="", title="Show Integrations", type="success", icon="el-icon-check", size="small", @click="chooseIntegration(scope.row)")
									el-button(v-b-tooltip.html.hover.top="", title="Edit Campaign", type="info", icon="el-icon-edit", size="small", @click="$router.push({name: 'iframe-edit-campaign', params: { id: scope.row.id  }})")


					//pre {{ this.integrationsList }}
					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")


						el-row
							el-col(:span="24")
								el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
									el-option(label="10 Per Page", :value="10")
									el-option(label="20 Per Page", :value="20")
									el-option(label="50 Per Page", :value="50")
									el-option(label="100 Per Page", :value="100")
									el-option(label="200 Per Page", :value="200")
			el-col(:sm="24" :md="12")
				.center-element
					h5(v-if="!this.currentCampaign") To view associated integrations, choose a campaign and click on the green button
					div(v-else="")
						h3 <b>Associated Integrations</b>
						small Drag the integrations to change the priority.
						br
						small Add new integrations associated list
					br
					div(v-if="currentCampaign")
						label.label-description.m-3( v-if="integrationsList.length > 0") Entries for campaign:&nbsp;
							el-tooltip(:content="`ID: ${currentCampaign.uuid}`")
								span.chooseable "{{currentCampaign.name}}"

						el-empty.p-3(:image-size="20" v-if="integrationsList.length === 0")
							template(slot="description")
								span.label-description No Records for&nbsp;
									el-tooltip(:content="`ID ${currentCampaign.uuid}`")
										span.chooseable "{{currentCampaign.name}}"
									|...
						draggable.clickable.mt-0.clickable(v-model='integrationsListComputed' @change="changePriority")
							transition-group
								div(v-for='(element, idx) in integrationsList', :key='element.id')
									el-card.no-padd.mt-1.mb-1(:name='element.id' shadow="hover")
										.d-flex.justify-content-between
											div
												label.clickable(v-b-tooltip.html.hover-right="", title="Drag to change the priority") <b>Type:</b> {{element.name}},
												label.clickable.ml-2(v-b-tooltip.html.hover-right="", title="Drag to change the priority") <b>Note:</b> {{element.note}},
												label.clickable.ml-2(v-b-tooltip.html.hover-right="", title="Drag to change the priority") <b>Brand:</b> {{element.brand}}
											div
												el-button(v-b-tooltip.html.hover.top="", title="Edit network campaign entry", type="info", icon="el-icon-edit", size="small", @click="editNetworkCampaign(element.id)")
												el-button.ml-1(v-b-tooltip.html.hover.top="", title="Edit Integration entry", type="warning", icon="el-icon-tickets", size="small", @click="$router.push({name: 'iframe-edit-integration', params: { id: element.integration_id }})")
												el-button.ml-1(v-b-tooltip.html.hover.top="", title="Remove integration from campaign", type="danger", icon="el-icon-delete", size="small", @click="removeIntegrationEntry(idx, element.id)")

				el-form(:form="form")
					el-row(v-if="this.currentCampaign")
						el-col(:span="24")
							el-form-item
								.d-flex.justify-content-between
									//el-autocomplete.w-100( class="inline-input" :fetch-suggestions="querySearch" v-model="form.id" placeholder="CRM Type")
									el-select.w-100.mr-1(filterable="", v-model="form.id")
										el-option(value="" label="Choose an integration")
										el-option(v-for="o of integrations_options_aval" :label="o.bn + ' - ' + o.t + (o.n ? ` (${o.n})` : '')" :value="o.v")
									el-button(v-b-tooltip.html.hover.top="", title="Add integration", type="primary", icon="el-icon-plus", size="small", @click="addIntegrationEntry")

</template>

<style lang="scss" scoped>
.small-icon {
	padding: 9px;
}
.label-description {
	margin: 0;
	font-size: 14px;
	color: #909399;
}
</style>

<style lang="scss">
.el-tag + .el-tag {
	margin-left: 10px;
}
.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
.input-new-tag {
	width: 120px;
	margin-left: 10px;
	vertical-align: bottom;
}
.no-padd {
	.el-card__body {
		padding-top: 6px;
		padding-bottom: 6px;
		&:hover {
			background-color: #ecf4f5;
		}
	}
}
.override-width.search-dialog {
	@media only screen and (max-width: 767px) {
		.el-dialog {
			width: 90% !important;
		}
	}
}
</style>


<script>

import draggable from 'vuedraggable'
import campaign from "../form-components/campaign";

const $form = {
	uuid: '',
	id: '',
	language: '',
	override_params: [],
	fields_mapping: [],
	tg_channel_id: [],
	allow_duplicates: false,
	is_strict_phone: false,
	allow_redirect_params_reg: false,
	jc_step_1: '',
	jc_step_2: '',
	custom_fix_id: null,

	// Override Traffic Source Params
	override_tracking_params: 'OFF',
	incoming_raw_ts_param: '',
	outgoing_mapped_ts_param: '',
};

	export default {
		name: 'campaign-list',
		components: { draggable },
		data() {
			return {
				action: 'create',
				currentIntegration: null,
				inputVisible: false,
				inputValue: '',
				showIntegrationsFlag: false,
				busy: false,
				list: [],
				count: 0,
				setIntegrationModal: false,
				form: JSON.parse(JSON.stringify($form)),
				rules: {

				},
				counter: {
					override_params: 0,
					fields_mapping: 0,
				},
				paginate: {
					keyword: '',
					limit: 10,
					page: 1,
					in_use: ''
				},
				currentCampaign: null,
				integrationsList: [],
				integrations_options: [],
				language_options: [],
				chosenOptions: [],
				customFixOptions: []
			}
		},
		computed: {
			integrationsListComputed: {
				get() {
					return this.integrationsList;
				},
				set(value) {
					this.integrationsList = value;
				}
			},
			integrations_options_aval() {
				let newArr = [];

				this.integrations_options.forEach(e =>  {
					if(!this.chosenOptions.includes(e.v)) {
						newArr.push(e);
					}
				});

				return newArr;
			}
		},
		async created() {
			this.fetchData()
				.then(() => {
					this.chosenOptions = this.integrationsList.map(e => e.id);
				})
			let options = await this.$apix.sendHttpRequest('GET', 'components/options', {filters: '3673e785-6d24-40ae-a91b-d7dac9e4c50b,9e832481-2c11-4021-ba13-0dcc5487e3f7,e91556b8-c5da-442f-a0bf-31f2ce567abe'});
			this.integrations_options = options.integration_extra;
			this.integrations_options_hash = {};
			this.integrations_options.forEach(e => this.integrations_options_hash[e.v] = e);
			this.language_options = options.languages;
			this.customFixOptions = options.custom_fix;
		},
		methods: {
			addRow(type) {
				this.form[type].push({key: `key_${this.counter[type]}`, value: `value_${this.counter[type]}`});
				this.counter[type]++;
			},
			querySearch(qs, cb) {
				let typeOptions = this.typeOptions;
				let results = qs ? typeOptions.filter(this.createFilter(qs)) : typeOptions
				cb(results)
			},
			async fetchData(cb) {
				setTimeout(() => {
					this.$apix.sendHttpRequest('GET', 'campaign/list', Object.assign({}, {...this.paginate}, {...this.filters}))
							.then(res => {
								this.list = res.rows;
								this.count = res.count;
								if(cb) cb();
							})
				}, 200)
			},
			chooseIntegration(obj) {
				this.integrationsList = [];
				this.currentCampaign = obj;
				let integrationsList = [];
				this.currentCampaign.integrations.forEach(itg => {
					const obj = {
						name: itg.type,
						note: itg.note,
						brand: itg.brand.name,
						brand_id: itg.brand.name,
						integration_id: itg.id,
						id: itg.id,
						priority: itg.NetworkCampaign.priority,
						campaign_id: itg.NetworkCampaign.campaign_id,
						network_campaign: itg.NetworkCampaign
					};

					integrationsList.push(obj);
				});
				this.integrationsList = integrationsList.sort((a,b)=> (a.priority > b.priority ? 1 : -1));
				this.chosenOptions = [...this.integrationsList.map(e => e.id)];
			},
			editNetworkCampaign(id) {

				this.form = JSON.parse(JSON.stringify($form));
				this.setIntegrationModal = true;
				this.action = 'edit';

				let dataSource = this.integrationsList.find(e => e.id === id);

				this.currentIntegration = dataSource;
				this.form = {
					id: 												id,
					uuid: 											dataSource.network_campaign.uuid,
					language: 									dataSource.network_campaign.language,
					override_params: 						dataSource.network_campaign.override_params,
					fields_mapping: 						dataSource.network_campaign.fields_mapping,
					tg_channel_id: 							dataSource.network_campaign.tg_channel_id,
					allow_duplicates: 					dataSource.network_campaign.allow_duplicates,
					is_strict_phone: 						dataSource.network_campaign.is_strict_phone,
					allow_redirect_params_reg: 	dataSource.network_campaign.allow_redirect_params_reg,
					jc_step_1: 									dataSource.network_campaign.jc_step_1,
					jc_step_2: 									dataSource.network_campaign.jc_step_2,
					custom_fix_id: 							dataSource.network_campaign.custom_fix_id,

					// Override Traffic Source Params
					override_tracking_params: 	dataSource.network_campaign.override_tracking_params,
					incoming_raw_ts_param: 			dataSource.network_campaign.incoming_raw_ts_param,
					outgoing_mapped_ts_param: 	dataSource.network_campaign.outgoing_mapped_ts_param,
				}
				this.counter.override_params = 0;
				this.counter.fields_mapping = 0;
				this.form.override_params = [];
				this.form.fields_mapping = [];
				Object.entries(dataSource.network_campaign.override_params).forEach(e => {

					this.counter.override_params++;
					this.form.override_params.push({key: e[0], value: e[1]})
				});

				Object.entries(dataSource.network_campaign.fields_mapping).forEach(e => {
					this.counter.fields_mapping++;
					this.form.fields_mapping.push({key: e[0], value: e[1]})
				});
			},
			removeIntegrationEntry(idx, id) {
				let that = this;
				this.$confirm('Are you sure that you want to delete this entry? This operation cannot be undone!', 'Lead Remove Alert')
						.then(() => {
							try {
								let dataSource = that.integrationsList.find(e => e.id === id);

								let loader = that.$loading.show();
								that.integrationsList.splice(idx, 1)
								that.$apix.sendHttpRequest('DELETE', 'campaign/network/' + dataSource.network_campaign.uuid)
									.then(() => {
										that.chosenOptions = this.integrationsList.map(e => e.id);
										that.$notify.success('Entry removed successfully');
										that.fetchData();
										loader.hide();
									})
										.catch(err => {
											loader.hide();
										})

							} catch (err) {
								console.error(err);
							}
						})
						.catch(err => {
							console.error(err);
						});
			},
			handleClose(tag) {
				this.form.tg_channel_id.splice(this.form.tg_channel_id.indexOf(tag), 1);
			},
			showInput() {
				this.inputVisible = true;
				this.$nextTick(_ => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},
			handleInputConfirm() {
				let inputValue = this.inputValue;
				if(!inputValue || inputValue === '') {
					this.inputVisible = false;
					this.inputValue = '';
				} else if (inputValue && inputValue.length > 10 && inputValue.length < 15) {
					this.form.tg_channel_id.push(inputValue);
					this.inputVisible = false;
					this.inputValue = '';
				} else {
					this.$notify.error({title: 'Validation Error', message: 'Validation failed. Telegram channel must consist of either 11 or 14 characters'})
				}
			},
			addIntegrationEntry() {
				if(!this.form.id) return this.$notify.error({title: 'Validation Error', message: 'You must choose an integration to proceed'})
				this.setIntegrationModal = true;
				this.action = 'create';
			},

			changePriority() {

				const payload = { list: this.integrationsListComputed.map(e => e.network_campaign.uuid), campaign_id: this.currentCampaign.id };
				this.$apix.sendHttpRequest('POST', 'campaign/network/sort', payload)
					.then(res => {
						this.$notify.success({title: 'API Response', message: 'Saved new priority for this campaign'});
						this.fetchData()
					})
					.catch(err => this.$notify.error({title: 'API Error', message: 'Changing priority failed with error: ' + err.message}))
			},
			setNetworkCampign() {


				if(this.action === 'create') {

					this.integrationsList.push({
						id: this.form.id,
						integration_id: this.form.id,
						campaign_id: this.currentCampaign.id,
						name: this.integrations_options_hash[`${this.form.id}`].t,
						brand: this.integrations_options_hash[`${this.form.id}`].bn,
						note: this.integrations_options_hash[`${this.form.id}`].n,
					});

					const payload = {
						...this.form,
						campaign_id: this.currentCampaign.id,
						integration_id: this.form.id
					};

					payload.override_params = {};
					payload.fields_mapping = {};
					this.form.override_params.forEach(e => payload.override_params[e.key] = e.value);
					this.form.fields_mapping.forEach(e => payload.fields_mapping[e.key] = e.value);

					this.$apix.sendHttpRequest('POST', 'campaign/network/', payload)
						.then(res => {
							this.integrationsList[this.integrationsList.length - 1].network_campaign = res
							this.fetchData();
							this.chosenOptions = this.integrationsList.map(e => e.id);
							this.setIntegrationModal = false;
							this.$notify.success({title: 'API Response', message: 'Network campaign created successfully'})
							setTimeout(() => {
								this.fetchData(() => {
									const sub = this.list.find(e => e.uuid === this.currentCampaign.uuid);
									this.chooseIntegration(sub);
								});
							}, 500);
						})
						.catch(err => {
							return this.$notify.error({title: 'API Error', message: 'Network campaign failed to create. Error: ' + err.message})
						});
				}

				else if(this.action === 'edit') {
					const payload = {
						...this.form,
						campaign_id: this.currentCampaign.id,
						integration_id: this.form.id
					};

					payload.override_params = {};
					payload.fields_mapping = {};
					this.form.override_params.forEach(e => payload.override_params[e.key] = e.value);
					this.form.fields_mapping.forEach(e => payload.fields_mapping[e.key] = e.value);

					this.$apix.sendHttpRequest('PUT', 'campaign/network/', payload)
						.then(res => {
							this.fetchData();
							this.setIntegrationModal = false;
							this.$notify.success({title: 'API Response', message: 'Network campaign updated successfully'})
							setTimeout(() => {
								this.fetchData(() => {
									const sub = this.list.find(e => e.uuid === this.currentCampaign.uuid);
									this.chooseIntegration(sub);
								});
							}, 500);
						})
						.catch(err => {
							return this.$notify.error({title: 'API Error', message: 'Network campaign failed to create. Error: ' + err.message})
						});
				}

				this.counter.override_params = 0;
				this.form.override_params = [];
				this.action = 'create';
				this.form = JSON.parse(JSON.stringify($form));
			},
			addIntegrationEntryOld() {
				// TODO - rewrite ugly function - add integration data and that's it
				this.integrationsList.push({
					id: this.form.id,
					campaign_id: this.currentCampaign.id,
					name: this.integrations_options_hash[`${this.form.id}`].t,
					brand: this.integrations_options_hash[`${this.form.id}`].bn,
					note: this.integrations_options_hash[`${this.form.id}`].n
				});
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('POST', 'campaign/set-integrations/' + this.currentCampaign.id, this.integrationsList)
					.then(() => {
						this.$notify.success({message: 'Integration successfully added', 'title': 'API Response'})
						loader.hide();
						this.fetchData().then()
						this.chosenOptions = this.integrationsList.map(e => e.id);
					})
					.catch(err => {
						loader.hide();
					})
			},
			changePriorityOld() {
				this.$apix.sendHttpRequest('POST', 'campaign/set-integrations/' + this.currentCampaign.id, this.integrationsList)
					.then(res => {
						this.$notify.success({message: 'Integrations priority changed!', title: 'API Response'})
					})
			}
		}
	}
</script>
